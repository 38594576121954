import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingOutlined = _resolveComponent("LoadingOutlined")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["m-button", [_ctx.btClass, (_ctx.disabled || _ctx.loading) ? 'disabled' : '' ]]),
    onTouchstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleTap && _ctx.handleTap(...args)))
  }, [
    _renderSlot(_ctx.$slots, "default", {}, undefined, true),
    _withDirectives(_createVNode(_component_LoadingOutlined, { class: "loading-icon" }, null, 512), [
      [_vShow, _ctx.loading]
    ])
  ], 34))
}