
import { defineComponent, computed } from "vue";
import MModal from "./MModal.vue";
export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    message: {
      type: String,
      default: "success",
    },
  },
  setup(props, context) {
    const _visible = computed({
      get() {
        return props.visible;
      },
      set(value) {
        context.emit("update:visible", value);
      },
    });
    return { _visible };
  },
  components: {
    MModal,
  },
});
