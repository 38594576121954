
import { computed, defineComponent, ref, watch, PropType } from "vue";
import MModal from "@/views/Payment/Mobile/Components/MModal.vue";
import MButton from "@/views/Payment/Mobile/Components/MButton.vue";
import { ExclamationCircleFilled } from "@ant-design/icons-vue";
const typeMap = {
  approve: {
    title: "Approve Payout",
    placeholder: "Please provide your comments for approval",
    required: false,
  },
  reject: {
    title: "Reject Payout",
    placeholder: "Please provide your comments for rejection",
    required: true,
  },
};
export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "approve",
    },
    formatkey: {
      type: String,
      required: true,
    },
    list: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const _visible = computed({
      get() {
        return props.visible;
      },
      set(value) {
        context.emit("update:visible", value);
      },
    });
    const title = computed(() => typeMap[props.type].title);
    const placeholder = computed(() => typeMap[props.type].placeholder);
    const required = computed(() => typeMap[props.type].required);
    const comment = ref<string>('')
    const handleConfirm = () => {
      context.emit("on-confirm", comment.value, () => {
        console.log("confirm")
      })
    }
    watch(_visible, (val) => {
      if (!val) {
        comment.value = '';
      }
    })
    const confirmDisabled = computed(() => props.type === 'reject' && comment.value === '')
    return {
      _visible,
      title,
      placeholder,
      required,
      comment,
      handleConfirm,
      confirmDisabled
    };
  },
  components: {
    MModal,
    MButton,
    ExclamationCircleFilled,
  },
});
