import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UpOutlined = _resolveComponent("UpOutlined")!

  return (_openBlock(), _createElementBlock("div", {
    class: "m-to-top",
    onTouchstart: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleToTop && _ctx.handleToTop(...args)))
  }, [
    _createVNode(_component_UpOutlined)
  ], 32))
}