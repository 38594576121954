
import { defineComponent, ref, PropType } from "vue";
import { LoadingOutlined } from '@ant-design/icons-vue';
type ButtonType = "primary" | "default";
export default defineComponent({
  name: "App",
  props: {
    type: {
      type: String as PropType<ButtonType>,
      default: "default",
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const btClass = ref(`m-bt-${props.type}`);
    const handleTap = () => {
      if (props.disabled || props.loading) return
      context.emit('tap')
    }
    return {
      btClass,
      handleTap
    };
  },
  components: {
    LoadingOutlined
  }
});
