
import { defineComponent, ref } from "vue";
import { CloseOutlined } from "@ant-design/icons-vue";
import useDOMCreate from "@/hooks/useDOMCreate";
export default defineComponent({
  name: "App",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const randomKey = ref<number>(Math.round(Math.random() * 10000))
    useDOMCreate("m-modal" + randomKey.value)
    const handleClose = () => {
      setTimeout(() => {
        context.emit("update:visible", false);
      }, 400)
    };
    return {
      randomKey,
      handleClose
    };
  },
  components: {
    CloseOutlined,
  },
});
