
import { defineComponent } from "vue";
import { UpOutlined } from '@ant-design/icons-vue';
export default defineComponent({
  name: "MToTop",
  props: {
    pageElement: {
      type: Object
    }
  },
  setup(props) {
    const handleToTop = () => {
      if (props.pageElement?.scrollTop) {
        props.pageElement.scrollTo({ top: 0, behavior: 'smooth' })
      }
    }
    return {
      handleToTop
    };
  },
  components: {
    UpOutlined
  }
});
